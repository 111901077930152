@import '../../styles/variables.scss';

.Slide {
  &__counter {
    font-family: 'Lato', sans-serif;
    font-size: 26px;
    position: fixed;
    right: 20px;
    top: 38px;
  }

  &--rainbow .Slide__counter {
    color: $rainbow-text-header-secondary;
  }

  &--dark .Slide__counter {
    color: $dark-text-header-secondary;
  }

  &--light .Slide__counter {
    color: $light-text-header-secondary;
  }
}
