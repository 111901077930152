@import '../../styles/variables.scss';

.EventExample {
  &__toggle {
    border: 1px solid $rainbow-text-primary;
    border-radius: 4px;
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: bold;
    margin: 10px auto 18px;
    max-width: 300px;
    min-width: 250px;
    padding: 6px;

    &:hover {
      cursor: pointer;
    }

    &--on {
      -webkit-box-shadow: 0 0 15px 8px $gradient-red, 0 0 15px 16px $gradient-yellow, 0 0 15px 24px $gradient-green, 0 0 15px 31px $gradient-teal, 0 0 15px 39px $gradient-blue, 5px 5px 15px 5px $gradient-purple;
      box-shadow: 0 0 15px 8px $gradient-red, 0 0 15px 16px $gradient-yellow, 0 0 15px 24px $gradient-green, 0 0 15px 31px $gradient-teal, 0 0 15px 39px $gradient-blue, 5px 5px 15px 5px $gradient-purple;
    }
  }
}
