@mixin chevron-desktop {
  border-style: solid;
  border-width: 0.3em 0.3em 0 0;
  content: '';
  display: inline-block;
  height: 60px;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 60px;
}

@mixin chevron-left-desktop {
  @include chevron-desktop;
  left: 0.25em;
  transform: rotate(-135deg);
}

@mixin chevron-right-desktop {
  @include chevron-desktop;
  left: 0;
  transform: rotate(45deg);
}

@mixin chevron-mobile {
  border-style: solid;
  border-width: 0.2em 0.2em 0 0;
  content: '';
  display: inline-block;
  height: 20px;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 20px;
}

@mixin chevron-left-mobile {
  @include chevron-mobile;
  left: 0.25em;
  transform: rotate(-135deg);
}

@mixin chevron-right-mobile {
  @include chevron-mobile;
  left: 0;
  transform: rotate(45deg);
}

@mixin accessible-hidden {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}
