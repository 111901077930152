@import '../../styles/variables.scss';

.Arrow {
  height: 100%;

  &--rainbow {
    background: $rainbow-accent-secondary;

    path {
      stroke: $rainbow-text-header-primary;
    }
  }

  &--dark {
    background: $dark-accent-secondary;

    path {
      stroke: $dark-text-header-secondary;
    }
  }

  &--light {
    background: $light-accent-secondary;

    path {
      stroke: $light-text-header-secondary;
    }
  }
}
