@import '../../styles/variables.scss';

.Footer {
  bottom: 0;
  display: flex;
  position: fixed;
  right: 0;

  &::before {
    content: "";
    border-bottom-width: $footer-height-mobile;
    border-bottom-style: solid;
    border-left: $footer-height-mobile solid transparent;
    height: 0;
    transform: translate(-$footer-height-mobile);
    width: 0;
  }

  &__link {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: $footer-height-mobile;
    padding-right: $footer-height-mobile;
    text-decoration: none;
  }
}

.App--rainbow {
  .Footer {
    background-color: $rainbow-accent-secondary;

    &::before {
      border-bottom-color: $rainbow-accent-secondary;
    }

    &__link {
      color: $rainbow-text-header-primary;
    }
  }
}

.App--dark {
  .Footer {
    background-color: $dark-accent-secondary;

    &::before {
      border-bottom-color: $dark-accent-secondary;
    }

    &__link {
      color: $dark-text-header-primary;
    }
  }
}

.App--light {
  .Footer {
    background-color: $light-accent-secondary;

    &::before {
      border-bottom-color: $light-accent-secondary;
    }

    &__link {
      color: $light-text-primary;
    }
  }
}

/* XL Desktop Screen Size */

@media (min-width: $screen-lg-min) {
  .Footer {
    &::before {
      border-bottom-width: $footer-height-desktop;
      border-left: $footer-height-desktop solid transparent;
      transform: translate(-$footer-height-desktop);
    }

    &__link {
      font-size: 20px;
      line-height: $footer-height-desktop;
      padding-right: $footer-height-desktop;
    }
  }
}
