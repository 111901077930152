@import '../../styles/variables.scss';

.Header {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100vw;

  &__heading--main,
  &__heading--secondary {
    margin: 0;
    width: 100%;
  }

  &__heading--main {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: bold;
    padding: 2px;
    text-align: center;

    a {
      text-decoration: none;
    }
  }

  &__heading--secondary {
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;
  }

  &__counter {
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    position: fixed;
    right: 10px;
    top: 8px;
  }

  &--rainbow {
    .Header__heading--main {
      background-color: $rainbow-accent-primary;
    }

    .Header__counter,
    .Header__counter-title,
    .Header__heading--secondary {
      color: $rainbow-text-header-secondary;
    }

    &,
    a {
      color: $rainbow-text-header-primary;
    }

    .Header__triangle {
      border-top-color: $rainbow-accent-primary;
    }

    .Logo {
      circle {
        fill: $rainbow-text-header-secondary;
      }

      ellipse,
      path {
        stroke: $rainbow-text-header-secondary;
      }
    }
  }

  &--dark {
    .Header__heading--main {
      background-color: $dark-accent-primary;
    }

    .Header__counter,
    .Header__counter-title,
    .Header__heading--secondary {
      color: $dark-text-header-secondary;
    }

    &,
    a {
      color: $dark-text-header-primary;
    }

    .Header__triangle {
      border-top-color: $dark-accent-primary;
    }

    .Logo {
      circle {
        fill: $dark-text-header-secondary;
      }

      ellipse,
      path {
        stroke: $dark-text-header-secondary;
      }
    }
  }

  &--light {
    .Header__heading--main {
      background-color: $light-accent-primary;
    }

    .Header__counter,
    .Header__counter-title,
    .Header__heading--secondary {
      color: $light-text-header-secondary;
    }

    &,
    a {
      color: $light-text-header-primary;
    }

    .Header__triangle {
      border-top-color: $light-accent-primary;
    }

    .Logo {
      circle {
        fill: $light-text-header-secondary;
      }

      ellipse,
      path {
        stroke: $light-text-header-secondary;
      }
    }
  }
}

/* XL Desktop Screen Size */

@media (min-width: $screen-lg-min) {
  .Header {
    flex-direction: row;
    height: $header-height-desktop;
    justify-content: space-between;
    position: fixed;

    &__heading--main,
    &__heading--secondary {
      width: 50%;
    }

    &__heading--main {
      font-size: 38px;
      padding: 16px;
      text-align: right;
    }

    &__heading--secondary {
      font-size: 60px;
      line-height: 80px;
      order: 4;
      padding-left: 16px;
      text-align: left;
    }

    &__triangle {
      border-right: $header-height-desktop solid transparent;
      border-top-width: $header-height-desktop;
      border-top-style: solid;
      height: 0;
      width: 0;
    }

    &__counter {
      font-size: 26px;
      right: 20px;
    }
  }
}
