@import '../../styles/variables.scss';

.TeachPage {
  max-height: calc(100vh - 200px);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &__heading {
    font-size: 24px;
    margin: 0 auto 80px;
    text-align: center;
  }

  &__main {
    font-size: 18px;
    margin: 30px auto;
    width: 80vw;
  }

  &__listItem {
    margin-bottom: 40px;
  }

  &__banner {
    text-align: center;
    width: 100%;
  }

  &__link {
    border-bottom: 2px solid transparent;
    text-decoration: none;

    &--rainbow {
      color: $rainbow-text-primary;

      &:hover,
      &:focus {
        border-bottom-color: $rainbow-text-primary;
        transition: border-bottom-color 0.2s;
      }
    }

    &--dark {
      color: $dark-text-primary;

      &:hover,
      &:focus {
        border-bottom-color: $dark-text-primary;
        transition: border-bottom-color 0.2s;
      }
    }

    &--light {
      color: $light-text-primary;

      &:hover,
      &:focus {
        border-bottom-color: $light-text-primary;
        transition: border-bottom-color 0.2s;
      }
    }
  }

  &__image-wrapper {
    border-radius: 5px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
    width: 80vw;

    &--rainbow {
      background: $rainbow-background-secondary;
      border-top: 15px $rainbow-accent-primary solid;
    }

    &--dark {
      background: $dark-background-secondary;
      border-top: 15px $dark-accent-primary solid;
    }

    &--light {
      background: $light-background-secondary;
      border-top: 15px $light-accent-primary solid;
    }
  }

  &__image {
    width: 100%;
  }
}

/* XL Desktop Screen Size */

@media (min-width: $screen-lg-min) {
  .TeachPage {
    max-height: calc(100vh - 220px);

    &__heading {
      font-size: 40px;
      margin: 0 auto 30px;
    }

    &__main {
      align-items: center;
      display: flex;
      font-size: 30px;
      justify-content: space-between;
      margin: 0 auto;
      position: relative;
      width: 80vw;
    }

    &__list {
      flex-grow: 1;
      max-width: 45%;
    }

    &__image-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 0;
      height: 100%;
      justify-content: center;
      margin: 0 auto;
      max-height: 700px;
      max-width: 820px;
    }

    &__list + &__image-container {
      margin: 0;
      max-height: 620px;
      max-width: 600px;
    }

    &__image-wrapper {
      margin-bottom: 30px;
      max-height: 100%;
      overflow-y: scroll;
      width: 100%;
    }
  }
}
