/* stylelint-disable no-descending-specificity */

@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.VoteButton {
  margin: 20px 0;
  width: 100%;

  &__input {
    @include accessible-hidden;
  }

  &__label {
    border-radius: 5px;
    display: inline-block;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    padding: 10px;
    position: relative;
    text-align: center;
    width: 100%;
  }

  &__input:focus + &__label,
  &__label:hover {
    cursor: pointer;

    .VoteButton__arrow {
      opacity: 1;
      transition: opacity 0.8s;
    }
  }

  &__input:checked + .VoteButton__label {
    .VoteButton__arrow {
      opacity: 1;
    }
  }

  &__arrow {
    border-radius: 0 5px 5px 0;
    height: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 70px;
  }

  &--rainbow {
    .VoteButton__label {
      background: $rainbow-background-button;
      /* For browsers that do not support gradients */
      background: -webkit-linear-gradient(left, #ddbdc0, #d8d8ba, #b8d8bc, #abcada, #b7b4db, #d6bddd);
      /* For Safari 5.1 to 6.0 */
      background: -o-linear-gradient(right, #ddbdc0, #d8d8ba, #b8d8bc, #abcada, #b7b4db, #d6bddd);
      /* For Opera 11.1 to 12.0 */
      background: -moz-linear-gradient(right, #ddbdc0, #d8d8ba, #b8d8bc, #abcada, #b7b4db, #d6bddd);
      /* For Firefox 3.6 to 15 */
      background: linear-gradient(to right, #ddbdc0, #d8d8ba, #b8d8bc, #abcada, #b7b4db, #d6bddd);
      /* Standard syntax (must be last) */

      color: $rainbow-text-secondary;
    }

    .VoteButton__arrow {
      background: $rainbow-accent-secondary;
    }
  }

  &--dark {
    .VoteButton__label {
      background-color: $dark-background-button;
    }

    .VoteButton__arrow {
      background: $dark-accent-secondary;
    }
  }

  &--light {
    .VoteButton__label {
      background-color: $light-background-button;
    }

    .VoteButton__arrow {
      background: $light-accent-secondary;
    }
  }
}

/* XL Desktop Screen Size */

@media (min-width: $screen-lg-min) {
  .VoteButton {
    &__label {
      font-size: 28px;
      padding: 20px;
    }
  }
}
