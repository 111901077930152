/* stylelint-disable no-descending-specificity */

@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.ThemeButton {
  &__input,
  &__label span {
    @include accessible-hidden;
  }

  &__label {
    align-items: center;
    border-bottom: 4px solid transparent;
    display: flex;
    height: 30px;
    justify-content: center;
    margin-right: 8px;

    &--rainbow:focus,
    &--rainbow:hover {
      border-bottom-color: $rainbow-text-header-secondary;
      transition: border-bottom-color 0.2s;
    }

    &--dark:focus,
    &--dark:hover {
      border-bottom-color: $dark-text-header-secondary;
      transition: border-bottom-color 0.2s;
    }

    &--light:focus,
    &--light:hover {
      border-bottom-color: $light-text-header-secondary;
      transition: border-bottom-color 0.2s;
    }

    .ThemeIcon {
      height: 24px;
      width: 24px;

      &--rainbow {
        path {
          stroke: $disabled-grey;
        }
      }

      &--crescent {
        height: 18px;

        path {
          fill: $disabled-grey;
          stroke: $disabled-grey;
        }
      }

      &--sun {
        height: 24px;

        path {
          fill: $disabled-grey;
        }
      }
    }
  }

  &__label:hover,
  &__input:focus + &__label,
  &__input:checked + &__label {
    cursor: pointer;
  }

  &__label--rainbow:hover,
  &__input--rainbow:focus + &__label--rainbow,
  &__input--rainbow:checked + &__label--rainbow {
    .ThemeIcon--rainbow {
      path {
        stroke: $rainbow-text-header-secondary;
      }
    }

    .ThemeIcon--crescent {
      path {
        fill: $rainbow-text-header-secondary;
        stroke: $rainbow-text-header-secondary;
      }
    }

    .ThemeIcon--sun {
      path {
        fill: $rainbow-text-header-secondary;
      }
    }
  }

  &__input--rainbow:focus + &__label--rainbow,
  &__label--rainbow:hover {
    border-bottom-color: $rainbow-text-header-secondary;
  }

  &__input--dark:focus + &__label--dark,
  &__label--dark:hover,
  &__input--dark:checked + &__label--dark {
    .ThemeIcon--rainbow {
      path {
        stroke: $dark-text-header-secondary;
      }
    }

    .ThemeIcon--crescent {
      path {
        fill: $dark-text-header-secondary;
        stroke: $dark-text-header-secondary;
      }
    }

    .ThemeIcon--sun {
      path {
        fill: $dark-text-header-secondary;
      }
    }
  }

  &__label--dark:hover,
  &__input--dark:focus + &__label--dark {
    border-bottom-color: $dark-text-header-secondary;
  }

  &__label--light:hover,
  &__input--light:focus + &__label--light,
  &__input--light:checked + &__label--light {
    .ThemeIcon--rainbow {
      path {
        stroke: $light-text-header-secondary;
      }
    }

    .ThemeIcon--crescent {
      path {
        fill: $light-text-header-secondary;
        stroke: $light-text-header-secondary;
      }
    }

    .ThemeIcon--sun {
      path {
        fill: $light-text-header-secondary;
      }
    }
  }

  &__label--light:hover,
  &__input--light:focus + &__label--light {
    border-bottom-color: $light-text-header-secondary;
  }
}

/* XL Desktop Screen Size */
@media (min-width: $screen-lg-min) {
  .ThemeButton {
    &__label {
      border-bottom: 4px solid transparent;
      height: 50px;

      .ThemeIcon {
        height: 40px;
        width: 40px;

        &--crescent {
          height: 30px;
        }

        &--sun {
          height: 44px;
        }
      }
    }
  }
}
