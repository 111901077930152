@import '../../styles/variables.scss';

* {
  box-sizing: border-box;
}

.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  height: 100vh;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 100vw;

  &--rainbow {
    color: $rainbow-text-primary;

    background: $rainbow-background-primary;
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, $gradient-red, $gradient-yellow, $gradient-green, $gradient-teal, $gradient-blue, $gradient-purple);
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, $gradient-red, $gradient-yellow, $gradient-green, $gradient-teal, $gradient-blue, $gradient-purple);
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, $gradient-red, $gradient-yellow, $gradient-green, $gradient-teal, $gradient-blue, $gradient-purple);
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, $gradient-red, $gradient-yellow, $gradient-green, $gradient-teal, $gradient-blue, $gradient-purple);
    /* Standard syntax (must be last) */
  }

  &--dark {
    background-color: $dark-background-primary;
    color: $dark-text-primary;
  }

  &--light {
    background-color: $light-background-primary;
    color: $light-text-primary;
  }
}
