@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.ThemeSelect {
  align-items: center;
  display: flex;
  left: 50px;
  position: fixed;
  top: 40px;
  z-index: 10; // Fixing bug where slide silently covers the buttons

  legend {
    @include accessible-hidden;
  }
}

/* XL Desktop Screen Size */
@media (min-width: $screen-lg-min) {
  .ThemeSelect {
    top: 100px;
  }
}
