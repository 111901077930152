// Colors

// Rainbow Theme
$rainbow-background-primary: #f7db4f;
$rainbow-background-secondary: #2e3f4f;
$rainbow-background-button: #fdf2f4;
$rainbow-accent-primary: #21142e;
$rainbow-accent-secondary: #3e406b;

$rainbow-text-primary: #21142e;
$rainbow-text-secondary: #21142e;

$rainbow-text-header-primary:#ffffff;
$rainbow-text-header-secondary:#21142e;

// Dark Theme
$dark-background-primary: #26283b;
$dark-background-secondary: #272727;
$dark-background-button: #4e5c56;

$dark-accent-primary: #808043;
$dark-accent-secondary: #a83b3b;

$dark-text-primary: #ffffff;
$dark-text-secondary: #d1d161;

$dark-text-header-primary:#ffffff;
$dark-text-header-secondary:#61dafb;

// Light Theme
$light-background-primary: #b3d0c4;
$light-background-secondary: #d6d6eb;
$light-background-button: #63be97;

$light-accent-primary: #7a80a8;
$light-accent-secondary: #c1a23c;

$light-text-primary: #21142e;
$light-text-secondary: #21142e;

$light-text-header-primary:#ffffff;
$light-text-header-secondary:#21142e;

$dark-grey: #2a363b;
$disabled-grey: #707070;
$black: #000000;

// Superhero colors
$pistachio: #93c178;
$pistachio-light: #a9cd93;

$queenblue: #465c8b;
$queenblue-dark: #394b71;

$palecerulean: #98c1d9;
$palecerulean-dark: #5096bf;

// Rainbow Gradient
$gradient-red: #ddbdc0;
$gradient-yellow: #d8d8ba;
$gradient-green: #b8d8bc;
$gradient-teal: #abcada;
$gradient-blue: #b7b4db;
$gradient-purple: #d6bddd;
// Sizing

$header-height-desktop: 80px;
$header-height-mobile: 30px;

$footer-height-desktop: 50px;
$footer-height-mobile: 20px;

$screen-lg-min: 1225px;
/* Large screen / wide desktop */
