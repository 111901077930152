/* stylelint-disable no-descending-specificity */

@import '../../styles/variables.scss';

.VotePage {
  &__form {
    border-radius: 5px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
    margin: 0 auto;
    padding: 8px 10px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;

    &--rainbow {
      background: $rainbow-background-secondary;
      border-top: 15px $rainbow-accent-primary solid;

      .VotePage__heading {
        color: $rainbow-text-header-primary;
      }

      .VotePage__link {
        color: $rainbow-text-header-primary;

        &:hover,
        &:focus {
          border-bottom: 1px solid $rainbow-text-header-primary;
        }
      }
    }

    &--dark {
      background: $dark-background-secondary;
      border-top: 15px $dark-accent-primary solid;

      .VotePage__link {
        color: $dark-text-primary;

        &:hover,
        &:focus {
          border-bottom: 1px solid $dark-text-primary;
        }
      }
    }

    &--light {
      background: $light-background-secondary;
      border-top: 15px $light-accent-primary solid;

      .VotePage__link {
        color: $light-text-primary;

        &:hover,
        &:focus {
          border-bottom: 1px solid $light-text-primary;
        }
      }
    }

    .VotePage__heading {
      font-family: 'Roboto', sans-serif;
      font-size: 24px;
      font-weight: 400;
      margin: 0 0 10px;
    }

    .VotePage__link {
      text-decoration: none;
    }

    .VotePage__list {
      list-style: none;
      margin-top: 30px;
      padding: 0;
      width: 100%;
    }

    .VotePage__listItem {
      width: 100%;
    }
  }
}

/* XL Desktop Screen Size */

@media (min-width: $screen-lg-min) {
  .VotePage {
    &__form {
      padding: 16px 50px 20px;
      width: 850px;

      .VotePage__heading {
        font-size: 38px;
      }

      .VotePage__link {
        font-size: 20px;
      }
    }
  }
}
