@import '../../styles/variables.scss';

.Logo {
  animation: react-rotation 80s infinite linear;
  height: 24px;
  order: 3;
  width: 100vw;

  @keyframes react-rotation {
    0% {
      transform: rotate(0deg);
      transform-origin: 50% 50%;
      transform-box: fill-box;
    }

    100% {
      transform: rotate(360deg);
      transform-origin: 50% 50%;
      transform-box: fill-box;
    }
  }
}

/* XL Desktop Screen Size */

@media (min-width: $screen-lg-min) {
  .Logo {
    height: $header-height-desktop;
    order: 3;
    width: auto;
  }
}
