@import '../../styles/variables.scss';

.Superhero__cape-shadow {
  fill: $pistachio;
}

.Superhero__cape-main {
  fill: $pistachio-light;
}

.Superhero__iris {
  fill: $pistachio;
}

.Superhero__boot-shadow {
  fill: $queenblue-dark;
}

.Superhero__boot {
  fill: $queenblue;
}

.Superhero__glove {
  fill: $queenblue;
}

.Superhero__tongue {
  fill: #dd3432;
}

.Superhero__mask {
  fill: $palecerulean-dark;
}

.Superhero__accessory--accent {
  fill: $queenblue-dark;
}

.Superhero__clothing--main {
  fill: $palecerulean;
}

.Superhero__clothing--accent {
  fill: $palecerulean-dark;
}

.Superhero__nose {
  fill: #cca183;
}

.Superhero__skin {
  fill: #dbb397;
}

.Superhero__mouth {
  fill: #212121;
}

.Superhero__cheeks {
  fill: #ef9884;
}

.Superhero__eye-shadow {
  fill: #e8e8e8;
}

.Superhero__eye--white {
  fill: #f4f3f2;
}

.Superhero__hair--accent {
  fill: #84563d;
}

.Superhero__hair--main {
  fill: #70482a;
}
