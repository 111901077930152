@import '../../styles/variables.scss';

.TitlePage {
  max-height: calc(100vh - 200px);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &__heading {
    font-size: 24px;
    margin: 0 auto 80px;
    text-align: center;
  }

  &__main {
    font-size: 18px;
    margin: 30px auto;
    width: 80vw;
  }

  &__banner {
    margin-bottom: 100px;
    text-align: center;
    width: 100%;
  }

  .Superhero {
    animation: superhero-flyby 10s infinite linear;
    display: block;
    height: 40px;
    margin: 0 auto;
  }

  @keyframes superhero-flyby {
    0% {
      transform: translateX(60vw) scale(0.3);
      transform-origin: center;
    }

    40% {
      transform: translateX(0) scale(1.1);
    }

    42% {
      transform: rotate(0deg) scale(1.1);
      transform-origin: center;
    }

    44% {
      transform: rotate(1deg) scale(1.1);
      transform-origin: center;
    }

    46% {
      transform: rotate(-2deg) scale(1.1);
      transform-origin: center;
    }

    48% {
      transform: rotate(1deg) scale(1.1);
      transform-origin: center;
    }

    50% {
      transform: translateX(0) scale(1.1);
    }

    52% {
      transform: translateX(15px) scale(1.1);
    }

    100% {
      transform: translateX(-60vw) scale(0.3);
    }
  }
}

/* XL Desktop Screen Size */

@media (min-width: $screen-lg-min) {
  .TitlePage {
    max-height: calc(100vh - 220px);

    &__heading {
      font-size: 36px;
      margin: 0 auto 80px;
    }

    &__main {
      font-size: 30px;
      margin: 30px auto;
      width: 80vw;
    }

    .Superhero {
      animation: superhero-flyby 20s infinite linear;
      height: 80px;
    }
  }
}
