@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.Nav {
  display: flex;
  justify-content: space-between;
  left: 0;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);

  &__text {
    @include accessible-hidden;
  }

  &__button {
    background-color: transparent;
    border: none;
    font-size: 20px;
    padding: 20px;

    &:focus,
    &:hover {
      cursor: pointer;
      outline: none; // replaced with equivalent visual que
    }
  }

  &__button--back::before {
    @include chevron-left-mobile;
  }

  &__button--forward::before {
    @include chevron-right-mobile;
  }

  &--rainbow {
    .Nav__button--back::before,
    .Nav__button--forward::before {
      border-color: $rainbow-accent-primary;
    }

    .Nav__button:focus.Nav__button--back::before,
    .Nav__button:hover.Nav__button--back::before,
    .Nav__button:focus.Nav__button--forward::before,
    .Nav__button:hover.Nav__button--forward::before {
      box-shadow: 5px -5px 3px 0 $rainbow-text-secondary;
    }
  }

  &--dark {
    .Nav__button--back::before,
    .Nav__button--forward::before {
      border-color: $dark-accent-primary;
    }

    .Nav__button:focus.Nav__button--back::before,
    .Nav__button:hover.Nav__button--back::before,
    .Nav__button:focus.Nav__button--forward::before,
    .Nav__button:hover.Nav__button--forward::before {
      box-shadow: 5px -5px 3px 0 $dark-text-secondary;
    }
  }

  &--light {
    .Nav__button--back::before,
    .Nav__button--forward::before {
      border-color: $light-accent-secondary;
    }

    .Nav__button:focus.Nav__button--back::before,
    .Nav__button:hover.Nav__button--back::before,
    .Nav__button:focus.Nav__button--forward::before,
    .Nav__button:hover.Nav__button--forward::before {
      box-shadow: 5px -5px 3px 0 $light-text-secondary;
    }
  }
}

/* XL Desktop Screen Size */

@media (min-width: $screen-lg-min) {
  .Nav {
    left: 40px;
    right: 40px;

    &__button--back::before {
      @include chevron-left-desktop;
    }

    &__button--forward::before {
      @include chevron-right-desktop;
    }
  }
}
